import { configureStore, combineReducers } from '@reduxjs/toolkit';
import account from './slices/accountSlice';
import projects from './slices/projectsSlice';

export const rootReducer = combineReducers({
  account,
  projects,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});
