import { Link, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from '../../router/paths';
import { signIn } from '../../redux/slices/accountSlice';
import { Layout } from '../../components';
import SignInForm from './components/signInForm/SignInForm';
import './SignInPage.css';

export default function SignInPage() {
  const { account, isLoading } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const handleSubmit = async (userData) => {
    dispatch(signIn(userData));
  };

  return account ? (
    <Navigate to={PATHS.MAIN} replace />
  ) : (
    <Layout navMenu={false} header={false} footer={false}>
      <div className="signin-page">
        <SignInForm onSubmit={handleSubmit} isLoading={isLoading} />
        <p>
          Новый пользователь? <Link to={PATHS.SIGN_UP}>Регистрация</Link>
        </p>
      </div>
    </Layout>
  );
}