import axios from 'axios';
import { API } from '../config';

async function createColumn(column) {
  const res = await API.post('/columns', column);
  return res.data;
}

async function getColumn(id) {
  const res = await API.get(`/columns/${id}`);
  return res.data;
}

async function updateColumn(column) {
  const { id, ...update } = column;
  const res = await API.put(`/columns/${id}`, update);
  return res.data;
}

async function updateColumnSet(set) {
  const res = await API.patch(`/columns`, set);
  return res.data;
}

async function deleteColumn(id) {
  const res = await API.delete(`/columns/${id}`);
  return res.data;
}


export default {
  createColumn,
  getColumn,
  updateColumn,
  updateColumnSet,
  deleteColumn,
};