import Button from '@mui/material/Button';

import Modal from '../modal/Modal';
import './ConfModal.css';


export default function ConfModal({ open, onClose, onConfirm, message }) {
  return (
    <Modal open={open} onClose={onClose} className="conf-modal">
      <h6 className="conf-modal__message">{message}</h6>
      <div className="conf-modal__buttons">
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Да
        </Button>
      </div>
    </Modal>
  );
}