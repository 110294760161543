import CircularProgress from '@mui/material/CircularProgress';

import './Spinner.css';


export default function Spinner() {
  return (
    <div className="spinner">
      <CircularProgress />
    </div>
  );
}