import axios from 'axios';
import { API, authAPI } from '../config';

async function signUp(user) {
  const res = await authAPI.post('/auth/signup', user);
  return res.data;
}

async function signIn(user) {
  const res = await authAPI.post('/auth/signin', user);
  return res.data;
}

async function refresh() {
  const res = await API.get('/auth/refresh', { withCredentials: true });
  return res.data;
}

async function signOut() {
  const res = await API.post('/auth/signout', { withCredentials: true });
  return res.data;
}

export default {
  signUp,
  signIn,
  refresh,
  signOut,
};