import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { stringAvatar } from '../../../../services/userService';
import './ProjectCard.css';

export default function ProjectCard({ project, onEdit, onDelete }){
  const users = [project.ownerRef, ...project.membersRefs];

  return (
    <Card className="project-card">
      <Link to={project.id} className="project-card__link">
        <h4 className="project-card__title">{project.title}</h4>
      </Link>
      <p className="project-card__desc">{project.description || ''}</p>
      <div className="project-card__footer">
        <AvatarGroup max={4}>
          {users.map((user) => (
            <Tooltip key={user.id} title={user.name} placement="top-start">
              <Avatar {...stringAvatar(user.name)} className="project-card__avatar" />
            </Tooltip>
          ))}
        </AvatarGroup>
        <div className="project-card__buttons">
          <Tooltip title="Редактировать" placement="top-start">
            <IconButton size="small" onClick={onEdit}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Удалить" placement="top-start">
            <IconButton size="small" onClick={onDelete}>
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
