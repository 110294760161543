import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { updateColumn } from '../../../../redux/slices/projectsSlice';
import { nameRules } from '../../../../constants/validationRules';
import './ColumnTitle.css';

export default function ColumnTitle({ column, onDelete, dragHandleProps }) {
  const [isEdit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: column });

  const handleEdit = () => {
    setAnchorEl(null);
    setEdit(true);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete(column);
  };

  const handleReset = () => {
    reset();
    setEdit(false);
  };

  const preHandleSubmit = async ({ title }) => {
    if (column.title !== title) {
      await dispatch(updateColumn({ id: column.id, title }));
    }
    setEdit(false);
  };

  const isDisabledSubmitBtn = Object.keys(errors).length > 0;

  return (
    <div className="column-title">
    {isEdit ? (
      <>
        <form id="column-title-form" onSubmit={handleSubmit(preHandleSubmit)} noValidate>
          <Controller
            name="title"
            control={control}
            rules={nameRules}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                fullWidth
                sx={{
                  border: 'none',
                }}
                error={!!errors.title}
                helperText={errors.title?.message || ''}
              />
            )}
          />
        </form>
        <IconButton type="submit" form="column-title-form" disabled={isDisabledSubmitBtn}>
          <DoneSharpIcon color="success" />
        </IconButton>
        <IconButton onClick={handleReset}>
          <CloseSharpIcon color="action" />
        </IconButton>
      </>
    ) : (
      <>
        <h3 className="column-title__title">{column.title}</h3>
        <div className="column-title__buttons">
          <IconButton {...dragHandleProps} className="column-title__drag-handle">
            <DragHandleIcon />
          </IconButton>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
          <Menu 
            anchorEl={anchorEl} 
            open={open} 
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEdit}>
              Изменить
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              Удалить
            </MenuItem>
          </Menu>
        </div>
      </>
    )}
    </div>
  );
}