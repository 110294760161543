export function getLocalValue(key) {
  const value = localStorage.getItem(key);
  return value && JSON.parse(value);
}

export function setLocalValue(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalValue(key) {
  localStorage.removeItem(key);
}
