import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import projectService from '../../api/services/projectApiService.js';
import columnService from '../../api/services/columnApiService.js';
import taskService from '../../api/services/taskApiService.js';
import { showErrorMessage } from '../../services/errorServices.js';

const initialState = {
  projects: [],
  project: null,
  isLoading: false,
  error: null,
};

export const getAllProjects = createAsyncThunk('projects/getAllProjects', async (_, { rejectWithValue }) => {
  try {
    const projects = await projectService.getAllProjects();
    return projects;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const createProject = createAsyncThunk('projects/createProject', async (project, { rejectWithValue }) => {
  try {
    const newProject = await projectService.createProject(project);
    return newProject;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const getProject = createAsyncThunk('projects/getProject', async (id, { rejectWithValue }) => {
  try {
    const project = await projectService.getProject(id);
    return project;
  } catch (error) {
    if (error.response?.status === 403) {
      showErrorMessage(error);
    }
    return rejectWithValue(error.response?.data);
  }
});

export const updateProject = createAsyncThunk('projects/updateProject', async (project, { rejectWithValue }) => {
  try {
    const updatedProject = await projectService.updateProject(project);
    toast.info('Проект успешно обновлен');
    return updatedProject;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const deleteProject = createAsyncThunk('projects/deleteProject', async (id, { rejectWithValue }) => {
  try {
    await projectService.deleteProject(id);
    toast.info('Проект удален');
    return;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const createColumn = createAsyncThunk('projects/createColumn', async (column, { rejectWithValue }) => {
  try {
    const newColumn = await columnService.createColumn(column);
    return newColumn;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const updateColumn = createAsyncThunk('projects/updateColumn', async (column, { rejectWithValue }) => {
  try {
    const updatedColumn = await columnService.updateColumn(column);
    return updatedColumn;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const updateColumnSet = createAsyncThunk('projects/updateColumnSet', async (updatedColumns, { rejectWithValue }) => {
  try {
    await columnService.updateColumnSet(updatedColumns);
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const deleteColumn = createAsyncThunk('projects/deleteColumn', async (id, { getState, rejectWithValue }) => {
  try {
    await columnService.deleteColumn(id);
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const createTask = createAsyncThunk('projects/createTask', async (task, { rejectWithValue }) => {
  try {
    const newTask = await taskService.createTask(task);
    return newTask;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const updateTask = createAsyncThunk('projects/updateTask', async (task, { rejectWithValue }) => {
  try {
    const updatedTask = await taskService.updateTask(task);
    return updatedTask;
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

export const updateTasksSet = createAsyncThunk('projects/updateTasksSet', async (updatedTasks, { rejectWithValue }) => {
  try {
    await taskService.updateTaskSet(updatedTasks);
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const deleteTask = createAsyncThunk('projects/deleteTask', async (id, { getState, rejectWithValue }) => {
  try {
    await taskService.deleteTask(id);
  } catch (error) {
    showErrorMessage(error);
    return rejectWithValue(error.response?.data);
  }
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateColumnSetState(state, action) {
      state.project.columns = action.payload;
    },
    updateTaskSetState(state, action) {
      state.project.tasks = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.project = null;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createColumn.fulfilled, (state, action) => {
        state.project?.columns.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateColumn.fulfilled, (state, action) => {
        state.project.columns = state.project.columns.map((column) =>
          column.id === action.payload?.id ? { ...column, ...action.payload } : column
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.project.tasks.push(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.project.tasks = state.project.tasks.map((task) =>
          task.id === action.payload?.id ? { ...task, ...action.payload } : task
        );
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(
          createProject.fulfilled, 
          getProject.fulfilled ,
          updateProject.fulfilled
        ), (state, action) => {
        state.project = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(
          getAllProjects.pending,
          createProject.pending,
          getProject.pending,
          updateProject.pending,
          deleteProject.pending
        ), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(
          getAllProjects.rejected,
          createProject.rejected,
          getProject.rejected,
          updateProject.rejected,
          deleteProject.rejected,
          createColumn.rejected,
          updateColumn.rejected,
          updateColumnSet.rejected,
          deleteColumn.rejected,
          createTask.rejected,
          updateTask.rejected,
          updateTasksSet.rejected,
          deleteTask.rejected,
        ), (state, action) => {
        state.error = action.payload || null;
        state.isLoading = false;
      });
  },
});

export const { updateColumnSetState, updateTaskSetState } = projectsSlice.actions;
export default projectsSlice.reducer;