import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


export default function ProtectedRoute({ children }) {
  const { account } = useSelector((state) => state.account);

  if (!account) {
    return <Navigate to="/signin" replace />;
  }

  if (!account.isVerified) {
    return <Navigate to="/not-verified" replace />;
  }

  return children ? children : <Outlet />;
}