import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { PATHS } from '../../router/paths';
import { PROJECT_ERR_MES, COLUMN_ERR_MES, TASK_ERR_MES } from '../../api/errorMessages';
import { 
  getProject, 
  createColumn, 
  updateColumnSetState,
  updateTaskSetState,
  updateColumnSet,
  updateTasksSet,
  deleteColumn,
} from '../../redux/slices/projectsSlice';
import { Layout, Spinner, AddButton, AddTitleForm, ConfModal } from '../../components';
import { orderAscending } from '../../services/sortService';
import Column from './components/column/Column';
import './ProjectPage.css';

export default function ProjectPage() {
  const { projects, project, error } = useSelector((state) => state.projects);
  const [isLoading, setLoading] = useState(true);
  const [openCreateColumnForm, setCreateColumnFormOpen] = useState(false);
  const [openCreateColumnModal, setCreateColumnModalOpen] = useState(false);
  const [openDeleteColumnModal, setDeleteColumnModalOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    dispatch(getProject(projectId)).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (error === PROJECT_ERR_MES.NO_ACCESS) {
      navigate(-1);
    }
    if (error === COLUMN_ERR_MES.NOT_RELEVANT || error === TASK_ERR_MES.NOT_RELEVANT) {
      setLoading(true)
      dispatch(getProject(projectId)).finally(() => setLoading(false));
    }
  }, [error, navigate]);

  const columns = useMemo(() => {
    return project?.columns ? [...project.columns].sort(orderAscending) : [];
  }, [project]);

  const tasks = useMemo(() => { 
    return project?.tasks ? project.tasks : [];
  }, [project]);

  const handleOnDragEnd = async ({ source, destination, type }) => {
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (type === 'column') {
      const updatedColumns = [...columns];
      const [removed] = updatedColumns.splice(source.index, 1);
      updatedColumns.splice(destination.index, 0, removed);

      const updatedColumnSetState = updatedColumns.map((column, index) => ({ ...column, order: index + 1 }))
      dispatch(updateColumnSetState(updatedColumnSetState));

      const updatedColumnSet = updatedColumns.map((column, index) => ({
        id: column.id,
        order: index + 1, 
        prevOrder: column.order
      }));
      await dispatch(updateColumnSet(updatedColumnSet));
      return;

    } else if (type === 'task') {
      const sourceColumnId = source.droppableId;
      const destColumnId = destination.droppableId;

      if (sourceColumnId === destColumnId) {
        const updatedTasksInColumn = tasks.filter((task) => task.columnRef === sourceColumnId).sort(orderAscending);

        const [removed] = updatedTasksInColumn.splice(source.index, 1);
        updatedTasksInColumn.splice(destination.index, 0, removed);

        const sortedTasksInColumn = updatedTasksInColumn.map((task, index) => ({ ...task, order: index + 1 }));
        const updatedTaskSetState = tasks.filter((task) => task.columnRef !== sourceColumnId).concat(sortedTasksInColumn);
        dispatch(updateTaskSetState(updatedTaskSetState));

        const updatedTaskSet = updatedTasksInColumn.map((task, index) => ({
          id: task.id,
          order: index + 1,
          prevOrder: task.order,
          columnId: task.columnRef,
          prevColumnId: task.columnRef,
        }));
        await dispatch(updateTasksSet(updatedTaskSet));

        return;
      } else {
        const updatedSourceColumnTasks = tasks
          .filter((task) => task.columnRef === sourceColumnId)
          .sort(orderAscending);
        const [removed] = updatedSourceColumnTasks.splice(source.index, 1);

        const updatedDestColumnTasks = tasks
          .filter((task) => task.columnRef === destColumnId)
          .sort(orderAscending);
        updatedDestColumnTasks.splice(destination.index, 0, removed);

        const sortedSourceColumnTasks = updatedSourceColumnTasks.map((task, index) => ({ ...task, order: index + 1 }));
        const sortedDestColumnTasks = updatedDestColumnTasks.map((task, index) => ({ ...task, order: index + 1, columnRef: destColumnId }));

        const updatedTaskSetState = tasks
          .filter((task) => task.columnRef !== sourceColumnId)
          .filter((task) => task.columnRef !== destColumnId)
          .concat(sortedSourceColumnTasks)
          .concat(sortedDestColumnTasks);
        dispatch(updateTaskSetState(updatedTaskSetState));

        const updatedSourceColumnTaskSet = updatedSourceColumnTasks.map((task, index) => ({
          id: task.id,
          order: index + 1,
          prevOrder: task.order,
          columnId: sourceColumnId,
          prevColumnId: task.columnRef,
        }));

        const updatedDestColumnTaskSet = updatedDestColumnTasks.map((task, index) => ({
          id: task.id,
          order: index + 1,
          prevOrder: task.order,
          columnId: destColumnId,
          prevColumnId: task.columnRef,
        }));
        await dispatch(updateTasksSet([...updatedSourceColumnTaskSet, ...updatedDestColumnTaskSet]));
      }
    }
  };

  const handleCreateColumn = async ({ title }) => {
    setCreateColumnFormOpen(false);
    const order = project?.columns.length + 1;
    await dispatch(createColumn({ projectId, title, order }));
  };

  const handleOpenDeleteColumnModal = (column) => {
    setSelectedColumn(column);
    setDeleteColumnModalOpen(true);
  };

  const handleDeleteColumn = async () => {
    await dispatch(deleteColumn(selectedColumn.id));

    const filteredColumns = columns.filter((column) => column.id !== selectedColumn.id).sort(orderAscending);
    const updatedColumnSetState = filteredColumns.map((column, index) => ({ ...column, order: index + 1 }));
    dispatch(updateColumnSetState(updatedColumnSetState));

    if (filteredColumns.length) {
      const updatedColumnSet = filteredColumns.map((column, index) => ({
        id: column.id,
        order: index + 1, 
        prevOrder: column.order
      }));
      await dispatch(updateColumnSet(updatedColumnSet));
    }
   
    setDeleteColumnModalOpen(false);
  };


  const title = !project || isLoading ? 'Проект' : project.title;

  return (
    <Layout title={title}>
      <div className="project-page">
        <div className="project-page__header">
          <Breadcrumbs>
            <Link to={PATHS.MAIN}>
              Проекты
            </Link>
            <p>{title}</p>
          </Breadcrumbs>
        </div>
        
        {isLoading ? (
          <Spinner />
        ) : !project ? (
          <p>Проект не найден</p>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="project-page__container">
              <Droppable droppableId="columns" direction="horizontal" type="column">
                {(provided) => (
                  <ul 
                    className="project-page__column-list" 
                    ref={provided.innerRef} 
                    {...provided.droppableProps}
                  >
                    {columns.map((column, index) => (
                      <Draggable key={column.id} draggableId={column.id} index={index}>
                        {(provided) => (
                          <div 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Column 
                              column={column} 
                              onDelete={handleOpenDeleteColumnModal}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
              <div className="project-page__add-btn">
                {openCreateColumnForm ? (
                  <AddTitleForm 
                    onSubmit={handleCreateColumn} 
                    onReset={() => setCreateColumnFormOpen(false)}
                  />
                ) : (
                  <AddButton 
                    label="Добавить столбец"
                    onClick={() => setCreateColumnFormOpen(true)}
                  />
                )}
              </div>
            </div>
          </DragDropContext>
        )}
      </div>
      <ConfModal 
        message="Вы уверены что хотите удалить столбец?"
        open={openDeleteColumnModal} 
        onClose={() => setDeleteColumnModalOpen(false)}
        onConfirm={handleDeleteColumn}
      />
    </Layout>
  );
}