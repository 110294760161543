import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { ERROR_MES_RUS } from '../api/errorMessagesRus.js';

export function showErrorMessage(error) {
  if (error instanceof AxiosError) {
    const serverMes = error.response?.data;
    if (serverMes || typeof serverMes === 'string') {
      const rusMessage = ERROR_MES_RUS[serverMes];
      if (rusMessage) {
        toast.error(rusMessage);
        return;
      }
    }
  }
  toast.error('Непредвиденная ошибка!');
}