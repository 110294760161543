import { createBrowserRouter } from "react-router-dom";

import * as Pages from '../pages';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import { Layout } from '../components';
import { PATHS } from './paths';

export const router = createBrowserRouter([
  {
    path: PATHS.SIGN_IN,
    element: <Pages.SignInPage/>,
    errorElement: <Pages.ErrorPage/>,
  },
  {
    path: PATHS.SIGN_UP,
    element: <Pages.SignUpPage/>,
    errorElement: <Pages.ErrorPage/>,
  },
  {
    path: PATHS.NOT_VERIFIED,
    element: <Pages.NotVerifiedPage/>,
    errorElement: <Pages.ErrorPage/>,
  },
  {
    path: PATHS.MAIN,
    element: <ProtectedRoute />,
    errorElement: <Pages.ErrorPage/>,
    children: [
      {
        index: true,
        element: <Pages.MainPage/>,
        errorElement: <Pages.ErrorPage/>,
      },
      {
        path: PATHS.ACCOUNT,
        element: <Pages.AccountPage/>,
        errorElement: <Pages.ErrorPage/>,
      },
      {
        path: ":projectId",
        element: <Pages.ProjectPage/>,
        errorElement: <Pages.ErrorPage/>,
      },
      {
        path: '*',
        element: <Pages.NotFoundPage/>,
        errorElement: <Pages.ErrorPage/>,
      },
    ]
  },
]);
