import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import './Task.css';

export default function Task({ task, onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleEdit = () => {
    setAnchorEl(null);
    onEdit(task);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete(task);
  };

  const priorityClass = task.priority === 1 ? 'task__priority--important' : task.priority === 2 ? 'task__priority--critical' : '';

  return (
    <Card className="task">
      <div className={!priorityClass ? 'task__priority' : `task__priority ${priorityClass}`}></div>
      <div className="task__content">
        <h4 className="task__title">{task.title}</h4>
        <div className="task__buttons">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
          <Menu 
            anchorEl={anchorEl} 
            open={open} 
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEdit}>
              Изменить
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              Удалить
            </MenuItem>
          </Menu>
        </div>
      </div>
    </Card>
  );
}