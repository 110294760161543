import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { nameRules, descriptionRules } from '../../../../constants/validationRules';
import './TaskForm.css';

export default function TaskForm({ task, onSubmit }) {
  const defaultValues = {
    title: task?.title,
    priority: task?.priority || '0',
    description: task?.description,
  };

  const {
    control,
    handleSubmit,
    reset,
    getDirtyValues, 
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues });

  const handlePreSubmit = (data) => {
    const changedData = {};
    for (const key in data) {
      if (defaultValues[key] !== data[key]) {
        changedData[key] = data[key];
      }
    }
    onSubmit(changedData);
  }

  const isDisabledSubmitBtn = Object.keys(errors).length > 0;

  return (
    <form className="task-form" onSubmit={handleSubmit(handlePreSubmit)} noValidate>
      <h3 className="task-form__title">
        {task ? 'Изменить задачу' : 'Добавить задачу'}
      </h3>
      <div className="task-form__inputs">
        <Controller
          name="title"
          control={control}
          rules={nameRules}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Название"
              placeholder="Название"
              autoComplete="off"
              error={!!errors.title}
              helperText={errors.title?.message || ''}
            />
          )}
        />
        <Controller
          name="priority"
          control={control}
          rules={descriptionRules}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="priority">Приоритет</InputLabel>
              <Select
                {...field}
                labelId="priority"
                label="Приоритет"
              >
                <MenuItem value={0}>Обычный</MenuItem>
                <MenuItem value={1}>Важный</MenuItem>
                <MenuItem value={2}>Критический</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={descriptionRules}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Описание"
              placeholder="Описание"
              autoComplete="off"
              multiline
              minRows={4}
              maxRows={4}
              error={!!errors.description}
              helperText={errors.description?.message || ''}
            />
          )}
        />
        <Button fullWidth type="submit" variant="contained" disabled={isDisabledSubmitBtn}>
          {task ? 'Сохранить' : 'Создать'}
        </Button>
      </div>
    </form>
  );
};