import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Droppable, Draggable } from '@hello-pangea/dnd';

import { createTask, updateTask, deleteTask, updateTaskSetState, updateTasksSet } from '../../../../redux/slices/projectsSlice';
import { Modal, ConfModal, AddButton, AddTitleForm } from '../../../../components';
import { orderAscending } from '../../../../services/sortService';
import ColumnTitle from '../columnTitle/ColumnTitle';;
import TaskForm from '../taskForm/TaskForm';
import Task from '../task/Task';
import './Column.css';

export default function Column({ column, onDelete, dragHandleProps, tasks }) {
  const { project } = useSelector((state) => state.projects);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openCreateTaskForm, setCreateTaskFormOpen] = useState(false);
  const [openUpdateTaskModal, setUpdateTaskModalOpen] = useState(false);
  const [openDeleteTaskModal, setDeleteTaskModalOpen] = useState(false);
  const dispatch = useDispatch();

  const columnTasks = project.tasks?.filter((task) => task.columnRef === column.id).sort(orderAscending) || [];

  const handleOpenUpdateTaskModal = (task) => {
    setSelectedTask(task);
    setUpdateTaskModalOpen(true);
  };

  const handleOpenDeleteTaskModal = (task) => {
    setSelectedTask(task);
    setDeleteTaskModalOpen(true);
  };

  const handleCreateTask = async ({ title }) => {
    setCreateTaskFormOpen(false);
    const order = columnTasks.length + 1;
    const columnId = column.id;
    await dispatch(createTask({ columnId, title, order }));
  };

  const handleUpdateTask = async (task) => {
    setUpdateTaskModalOpen(false);
    await dispatch(updateTask({ id: selectedTask.id, ...task }));
  };

  const handleDeleteTask = async () => {
    await dispatch(deleteTask(selectedTask.id));

    const filteredTasks = columnTasks.filter((task) => task.id !== selectedTask.id).sort(orderAscending);
    const sortedTasks = filteredTasks.map((task, index) => ({ ...task, order: index + 1 }));

    const updatedTaskSetState = project.tasks.filter((task) => task.columnRef !== column.id).concat(sortedTasks);
    dispatch(updateTaskSetState(updatedTaskSetState));

    if (filteredTasks.length) {
      const updatedTaskSet = filteredTasks.map((task, index) => ({
        id: task.id,
        order: index + 1,
        prevOrder: task.order,
        columnId: task.columnRef,
        prevColumnId: task.columnRef,
      }));

      await dispatch(updateTasksSet(updatedTaskSet));
    }

    setDeleteTaskModalOpen(false);
  };

  return (
    <div className="column">
      <ColumnTitle column={column} onDelete={onDelete} dragHandleProps={dragHandleProps} />
      <div className="column__container">
        <Droppable droppableId={column.id} type="task">
          {(provided) => (
            <ul className="tasks-list" ref={provided.innerRef} {...provided.droppableProps}>
              {columnTasks.map((task, index) => (
                <Draggable key={task.id} draggableId={task.id} index={index}>
                  {(provided) => (
                    <li 
                      className="task-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Task 
                        task={task} 
                        onEdit={handleOpenUpdateTaskModal} 
                        onDelete={handleOpenDeleteTaskModal}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
      <div>
        {openCreateTaskForm ? (
          <AddTitleForm 
            onSubmit={handleCreateTask} 
            onReset={() => setCreateTaskFormOpen(false)}
          />
        ) : (
          <AddButton 
            label="Добавить задачу"
            onClick={() => setCreateTaskFormOpen(true)}
          />
        )}
      </div>
      <Modal open={openUpdateTaskModal} onClose={() => setUpdateTaskModalOpen(false)}>
        <TaskForm onSubmit={handleUpdateTask} task={selectedTask} />
      </Modal>
      <ConfModal 
        message="Вы уверены что хотите удалить задачу?"
        open={openDeleteTaskModal} 
        onClose={() => setDeleteTaskModalOpen(false)}
        onConfirm={handleDeleteTask}
      />
    </div>
  );
}