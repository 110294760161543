import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material//Button';
import Grid from '@mui/material/Grid';

import { getAllProjects, createProject, updateProject, deleteProject } from '../../redux/slices/projectsSlice';
import { Layout, Spinner, Modal, ConfModal } from '../../components';
import ProjectForm from './components/projectForm/ProjectForm';
import ProjectCard from './components/projectCard/ProjectCard';
import ProjectAddBtn from './components/projectAddBtn/ProjectAddBtn';
import './MainPage.css';

export default function MainPage() {
  const { projects } = useSelector((state) => state.projects);
  const [isLoading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openCreateModal, setCreateModalOpen] = useState(false);
  const [openUpdateModal, setUpdateModalOpen] = useState(false);
  const [openDeleteModal, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProjects()).then(() => setLoading(false));
  }, []);

  const handleOpenUpdateModal = (project) => {
    setSelectedProject(project);
    setUpdateModalOpen(true);
  };

  const handleOpenDeleteModal = (project) => {
    setSelectedProject(project);
    setDeleteModalOpen(true);
  };

  const handleCreateProject = async (project) => {
    setCreateModalOpen(false);
    await dispatch(createProject(project));
    dispatch(getAllProjects())
  };

  const handleUpdateProject = async (project) => {
    setUpdateModalOpen(false);
    await dispatch(updateProject({ id: selectedProject.id, ...project }));
    dispatch(getAllProjects())
  };

  const handleDeleteProject = async () => {
    setDeleteModalOpen(false);
    await dispatch(deleteProject(selectedProject.id));
    dispatch(getAllProjects())
  };

  return (
    <Layout title="Проекты">
      <div className="main-page">
        {isLoading ? (
          <Spinner />
        ) : (
          <Grid container spacing={1}>
            {projects.map((project) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={project.id}>
                <ProjectCard 
                  project={project}
                  onEdit={() => handleOpenUpdateModal(project)} 
                  onDelete={() => handleOpenDeleteModal(project)}
                />
              </Grid>
            ))}
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <ProjectAddBtn onClick={() => setCreateModalOpen(true)} />
            </Grid>
          </Grid>
        )}
      </div>
      <Modal open={openCreateModal} onClose={() => setCreateModalOpen(false)}>
        <ProjectForm onSubmit={handleCreateProject} />
      </Modal>
      <Modal open={openUpdateModal} onClose={() => setUpdateModalOpen(false)}>
        <ProjectForm onSubmit={handleUpdateProject} project={selectedProject} />
      </Modal>
      <ConfModal 
        message="Вы уверены что хотите удалить проект?"
        open={openDeleteModal} 
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
      />
    </Layout>
  );
}