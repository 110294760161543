import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import './ProjectAddBtn.css';

export default function ProjectAddBtn({ onClick }){
  return (
    <Card className="project-aad-btn">
      <IconButton
        className="project-aad-btn__btn"
        onClick={onClick}
      >
        <AddBoxOutlinedIcon fontSize="large" color="inherit" />
      </IconButton>
      <p className="project-aad-btn__message">Добавить проект</p>
    </Card>
  );
};