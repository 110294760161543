import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { nameRules } from '../../constants/validationRules';
import './AddTitleForm.css';

export default function AddTitleForm({ onSubmit, onReset }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleReset = () => {
    reset();
    onReset();
  };

  const isDisabledSubmitBtn = Object.keys(errors).length > 0;

  return (
    <form className="add-title-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        name="title"
        control={control}
        rules={nameRules}
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value || ''}
            label="Название"
            placeholder="Название"
            autoComplete="off"
            error={!!errors.title}
            helperText={errors.title?.message || ''}
          />
        )}
      />
      <div className="add-title-form__buttons">
        <Button type="submit" variant="contained" disabled={isDisabledSubmitBtn}>
          Создать
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Отмена
        </Button>
      </div>
    </form>
  );
};