import axios from 'axios';
import { API } from '../config';

async function createTask(task) {
  const res = await API.post('/tasks', task);
  return res.data;
}

async function getTask(id) {
  const res = await API.get(`/tasks/${id}`);
  return res.data;
}

async function updateTask(task) {
  const { id, ...update } = task;
  const res = await API.put(`/tasks/${id}`, update);
  return res.data;
}

async function updateTaskSet(set) {
  const res = await API.patch(`/tasks`, set);
  return res.data;
}

async function deleteTask(id) {
  const res = await API.delete(`/tasks/${id}`);
  return res.data;
}

async function subscribeTask(id) {
  const res = await API.post(`/tasks/${id}/subscribe`);
  return res.data;
}

async function unsubscribeTask(id) {
  const res = await API.delete(`/tasks/${id}/subscribe`);
  return res.data;
}

export default {
  createTask,
  getTask,
  updateTask,
  updateTaskSet,
  deleteTask,
  subscribeTask,
  unsubscribeTask,
};