import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouterProvider } from "react-router-dom";

import { refreshToken } from '../redux/slices/accountSlice';
import { router } from '../router/router';
import { Spinner } from '../components';
import '../styles/normalize.css';
import '../styles/variables.css';
import './App.css';

export default function App() {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken()).finally(() => {
      setLoading(false);
    });
  }, []);

  return isLoading ? <Spinner /> : <RouterProvider router={router} />;
}
