import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

export default function AddButton({ label, onClick }) {
  return (
    <Button 
      variant="text" 
      color="inherit" 
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}