import { Link, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { PATHS } from '../../router/paths';
import { signUp } from '../../redux/slices/accountSlice';
import { Layout } from '../../components';
import SignUpForm from './components/signUpForm/SignUpForm';
import './SignUpPage.css';

export default function SignUpPage() {
  const { account, isLoading } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const handleSubmit = async (userData) => {
    dispatch(signUp(userData));
  };

  return account ? (
    <Navigate to={PATHS.MAIN} replace />
  ) : (
      <Layout navMenu={false} header={false} footer={false}>
      <div className="signup-page">
        <SignUpForm onSubmit={handleSubmit} isLoading={isLoading} />
        <p>
          Уже зарегистрированы? <Link to={PATHS.SIGN_IN}>Войти</Link>
        </p>
      </div>
    </Layout>
  );
}
