import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { nameRules, descriptionRules } from '../../../../constants/validationRules';
import './ProjectForm.css';

export default function ProjectForm({ project, onSubmit }) {
  const defaultValues = {
    title: project?.title,
    description: project?.description,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ defaultValues });

  const handlePreSubmit = (data) => {
    const changedData = {};
    for (const key in data) {
      if (defaultValues[key] !== data[key]) {
        changedData[key] = data[key];
      }
    }
    onSubmit(changedData);
  }

  const isDisabledSubmitBtn = Object.keys(errors).length > 0;

  return (
    <form className="project-form" onSubmit={handleSubmit(handlePreSubmit)} noValidate>
      <h3 className="project-form__title">
        {project ? 'Изменить проект' : 'Добавить проект'}
      </h3>
      <div className="project-form__inputs">
        <Controller
          name="title"
          control={control}
          rules={nameRules}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Название"
              placeholder="Название"
              autoComplete="off"
              error={!!errors.title}
              helperText={errors.title?.message || ''}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={descriptionRules}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value || ''}
              label="Описание"
              placeholder="Описание"
              autoComplete="off"
              multiline
              minRows={4}
              maxRows={4}
              error={!!errors.description}
              helperText={errors.description?.message || ''}
            />
          )}
        />
        <Button fullWidth type="submit" variant="contained" disabled={isDisabledSubmitBtn}>
          {project ? 'Сохранить' : 'Создать'}
        </Button>
      </div>
    </form>
  );
};