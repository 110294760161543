import axios from 'axios';
import { API } from '../config';

async function getAllProjects() {
  const res = await API.get('/projects');
  return res.data;
}

async function createProject(project) {
  const res = await API.post('/projects', project);
  return res.data;
}

async function getProject(id) {
  const res = await API.get(`/projects/${id}`);
  return res.data;
}

async function updateProject(project) {
  const { id, ...update } = project;
  const res = await API.put(`/projects/${id}`, update);
  return res.data;
}

async function deleteProject(id) {
  const res = await API.delete(`/projects/${id}`);
  return res.data;
}

async function inviteProjectMember(id, email) {
  const res = await API.post(`/projects/${id}/member`,  { email });
  return res.data;
}

async function deleteProjectMember(id, userId) {
  const res = await API.delete(`/projects/${id}/member/${userId}`);
  return res.data;
}

async function inviteProjectOwner(id, email) {
  const res = await API.post(`/projects/${id}/owner`,  { email });
  return res.data;
}

export default {
  getAllProjects,
  createProject,
  getProject,
  updateProject,
  deleteProject,
  inviteProjectMember,
  deleteProjectMember,
  inviteProjectOwner,
};