import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import { PATHS } from '../../../router/paths';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
import AccountMenu from '../accountMenu/AccountMenu';
import './Header.css';

export default function Header({ title }) {
  return (
    <header className="header">
      <div className="header__logo">
        <Tooltip title="Главная" placement="bottom-start">
          <Link to={PATHS.MAIN} className="header__link">
            <Logo className="header__image" />
          </Link>
        </Tooltip>
      </div>
      <h1 className="header__title">{title}</h1>
      <AccountMenu />
    </header>
  );
};